// prettier-ignore
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	@include font-alt();
	font-style: normal;
	line-height: 1.2;
}

h1,
.h1,
.text-1 {
	font-size: $h1-size;
}

h2,
.h2,
.text-2 {
	font-size: $h2-size;
}

h3,
.h3,
.text-3 {
	font-size: $h3-size;
}

h4,
.h4,
.text-4 {
	font-size: $h4-size;
}

h5,
.h5,
.text-5 {
	font-size: $h5-size;
}

h6,
.h6,
.text-6 {
	font-size: $h6-size;
}

.text-lg {
	font-size: $font-size-lg;
}

small,
.small,
.text-sm {
	font-size: $font-size-sm;
}

.text-xs {
	font-size: $font-size-xs;
}
