// Text Alignment
// ----------------------------------------

@each $breakpoint in map-keys($breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		.text-left#{$infix} {
			text-align: left !important;
		}
		.text-right#{$infix} {
			text-align: right !important;
		}
		.text-center#{$infix} {
			text-align: center !important;
		}
	}
}

// Text transform
// ----------------------------------------

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

// Font styles
// ----------------------------------------

.font-light {
	font-weight: $font-weight-thin !important;
}

.font-normal {
	font-weight: $font-weight-medium !important;
}

.font-bold {
	font-weight: $font-weight-bold !important;
}

.font-black {
	font-weight: $font-weight-black !important;
}

.font-italic {
	font-style: italic !important;
}

// Text colors
// ----------------------------------------

.text-color {
	color: var(--color-black) !important;
}

.text-muted {
	color: var(--color-gray-500) !important;
}

.text-inherit {
	color: inherit !important;
}

@each $name, $map in $theme-colors {
	.text-#{$name} {
		color: map-get($map, 'base') !important;
	}
}

// Text wrappers
// ----------------------------------------

.copy {
	@include text-content();

	// prettier-ignore
	h1, h2, h3, h4, h5, h6,
	p, ul, ol {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	ul,
	ol {
		li {
			&:not(:last-child) {
				margin-bottom: 15px;
			}
		}
	}

	a {
		color: var(--color-brand);
		transition: color 250ms ease-in-out;

		&:hover {
			color: var(--color-brand-light);
			text-decoration: underline;
		}
	}

	iframe {
		display: block;
		width: 100%;
		height: auto;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}
