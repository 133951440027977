.section {
	display: block;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	&:not(:first-child):not(.-pad) {
		margin-top: var(--ms-spacing-y);
	}

	&:not(:last-child):not(.-pad) {
		margin-bottom: var(--ms-spacing-y);
	}

	&.-pad {
		padding-top: var(--ms-spacing-y);
		padding-bottom: var(--ms-spacing-y);
	}
}
