// Flex
// ----------------------------------------

// prettier-ignore
@each $breakpoint in map-keys($breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		.flex-row#{$infix}					{ flex-direction: row !important; }
		.flex-column#{$infix}				{ flex-direction: column !important; }
		.flex-row-reverse#{$infix}			{ flex-direction: row-reverse !important; }
		.flex-column-reverse#{$infix}		{ flex-direction: column-reverse !important; }

		.flex-wrap#{$infix}					{ flex-wrap: wrap !important; }
		.flex-nowrap#{$infix}				{ flex-wrap: nowrap !important; }

		.flex-shrink-0#{$infix}				{ flex-shrink: 0 !important;}
		.flex-shrink-1#{$infix}				{ flex-shrink: 1 !important;}

		.flex-grow-0#{$infix}				{ flex-grow: 0 !important;}
		.flex-grow-1#{$infix}				{ flex-grow: 1 !important;}

		.justify-content-start#{$infix}  	{ justify-content: flex-start !important; }
		.justify-content-end#{$infix}    	{ justify-content: flex-end !important; }
		.justify-content-center#{$infix} 	{ justify-content: center !important; }
		.justify-content-between#{$infix}	{ justify-content: space-between !important; }
		.justify-content-around#{$infix} 	{ justify-content: space-around !important; }

		.justify-self-start#{$infix} 		{ justify-self: start !important; }
		.justify-self-end#{$infix} 			{ justify-self: end !important; }
		.justify-self-center#{$infix} 		{ justify-self: center !important; }

		.align-items-start#{$infix}			{ align-items: flex-start !important; }
		.align-items-end#{$infix}			{ align-items: flex-end !important; }
		.align-items-center#{$infix}  		{ align-items: center !important; }
		.align-items-baseline#{$infix}		{ align-items: baseline !important; }
		.align-items-stretch#{$infix} 		{ align-items: stretch !important; }

		.align-self-auto#{$infix}    		{ align-self: auto !important; }
		.align-self-start#{$infix}   		{ align-self: flex-start !important; }
		.align-self-end#{$infix}     		{ align-self: flex-end !important; }
		.align-self-center#{$infix}  		{ align-self: center !important; }
		.align-self-baseline#{$infix}		{ align-self: baseline !important; }
		.align-self-stretch#{$infix} 		{ align-self: stretch !important; }
	}
}
