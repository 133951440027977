.form {
	display: block;
}

form.form,
.form > form {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: repeat(var(--form-columns, 1), minmax(0, 1fr));
	gap: var(--form-gap-y, 20px) var(--form-gap-x, 20px);

	.form-required {
		grid-column: span var(--form-columns, 1);
	}
}
