// Theme Colors
// ----------------------------------------

$color-white: #ffffff;
$color-gray-100: #f0f4f7;
$color-gray-300: #d8e0e6;
$color-gray-500: #abbecc;
$color-gray-700: #798fa3;
$color-gray-900: #4d4d4f;
$color-black: #000000;

$color-brand: #008fd5;
$color-brand-light: #00aaff;
$color-brand-dark: #005c8a;

$color-success: #02b85d;
$color-success-light: #00d169;

$color-warning: #f0b800;
$color-warning-light: #ffcf33;

$color-danger: #ea484a;
$color-danger-light: #ff6164;

$theme-colors: (
	'brand': (
		'base': $color-brand,
		'light': $color-brand-light,
		'contrast': $color-white,
	),
	'success': (
		'base': $color-success,
		'light': $color-success-light,
		'contrast': $color-white,
	),
	'warning': (
		'base': $color-warning,
		'light': $color-warning-light,
		'contrast': $color-white,
	),
	'danger': (
		'base': $color-danger,
		'light': $color-danger-light,
		'contrast': $color-white,
	),
);

$neutral-colors: (
	'white': $color-white,
	'gray-100': $color-gray-100,
	'gray-300': $color-gray-300,
	'gray-500': $color-gray-500,
	'gray-700': $color-gray-700,
	'gray-900': $color-gray-900,
	'black': $color-black,
);

// Typography
// ----------------------------------------

$font-weight-thin: 100;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;

$line-height-base: 1.5;

$h1-size: 4.5rem;
$h2-size: 3.5rem;
$h3-size: 3rem;
$h4-size: 2.5rem;
$h5-size: 2.2rem;
$h6-size: 2rem;

$font-size-lg: 1.8rem;
$font-size-md: 1.6rem;
$font-size-sm: 1.4rem;
$font-size-xs: 1.2rem;
$font-size-xxs: 1rem;

$font-family-base: 'Roboto', sans-serif;
$font-weight-base: $font-weight-regular;

$font-family-alt: 'Teko', sans-serif;
$font-weight-alt: $font-weight-bold;

// Breakpoints
// ----------------------------------------

$breakpoints: (
	'xs': 0,
	'sm': 576px,
	'md': 768px,
	'lg': 992px,
	'xl': 1200px,
	'xxl': 1400px,
);

// Spacers
// ----------------------------------------

$spacer: 15px;

$spacers: (
	0: 0,
	1: 5px,
	2: 10px,
	3: 15px,
	4: 20px,
	5: 25px,
	6: 30px,
	7: 35px,
	8: 40px,
	9: 45px,
	10: 50px,
);

// Grid
// ----------------------------------------

$grid-size: 6;

$grid-gutters: (
	0: 0px,
	1: 15px,
);

// Display utilities
// ----------------------------------------

$display: none, inline-block, block, flex, inline-flex, initial, revert,
	contents;

// Size utilities
// ----------------------------------------

$sizes: (
	100: 100%,
	auto: auto,
);

// Misc
// ----------------------------------------

$transition-timing: 300ms;
$transition-easing: ease-in-out;
