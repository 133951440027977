// Display
// ----------------------------------------

@each $breakpoint in map-keys($breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $breakpoints);

		@each $value in $display {
			.d-#{$value}#{$infix} {
				display: $value !important;
			}
		}
	}
}
