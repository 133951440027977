html,
body {
	width: 100%;
	height: 100%;

	@include media-breakpoint-up('lg') {
		height: calc(100vh - var(--vh-offset, 0));
	}
}

body {
	background-color: $color-gray-100;

	&.ms-logged-out {
		background-color: $color-brand;
	}

	&.overlay-open {
		overflow: hidden !important;
	}
}

@include media-breakpoint-up('lg') {
	//prettier-ignore
	* {
		scrollbar-width: var(--scrollbar-style, auto); // "auto" or "thin"
		scrollbar-color: var(--scrollbar-thumb-color, var(--color-gray-500)) var(--scrollbar-track-color, var(--color-gray-100)); // scroll thumb & track
	}

	::-webkit-scrollbar {
		width: var(--scrollbar-size, 15px);
		height: var(--scrollbar-size, 15px);
		background-color: var(--scrollbar-track-color, var(--color-gray-100));
	}

	::-webkit-scrollbar-track {
		background-color: var(--scrollbar-track-color, var(--color-gray-100));
	}

	::-webkit-scrollbar-thumb {
		background-color: var(--scrollbar-thumb-color, var(--color-gray-500));
		border-radius: 999px;
		border: 4px solid var(--scrollbar-track-color, var(--color-gray-100));
	}

	::-webkit-scrollbar-corner {
		background-color: var(--scrollbar-track-color, var(--color-gray-100));
	}
}
