// Sizing
// ----------------------------------------

@each $prop, $abbrev in (width: w, height: h) {
	@each $size, $length in $sizes {
		.#{$abbrev}-#{$size} {
			#{$prop}: $length !important;
		}
	}
}
