// stylelint-disable declaration-no-important

// Background Colors
// ----------------------------------------

@each $name, $map in $theme-colors {
	.bg-#{$name} {
		background-color: map-get($map, 'base') !important;
	}
}

@each $name, $value in $neutral-colors {
	.bg-#{$name} {
		background-color: $value !important;
	}
}
