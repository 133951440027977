*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size: 10px;
}

body {
	color: var(--color-gray-900);
	font-size: 1.6rem;
	font-family: $font-family-base;
	font-weight: $font-weight-base;
	line-height: $line-height-base;
}

html,
body,
p,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}

ol,
ul {
	margin: 0;
	padding-left: 20px;
}

button,
input,
select,
textarea {
	margin: 0;
	color: var(--color-gray-900);
	font-size: inherit;
	font-family: inherit;
}

textarea {
	color: var(--color-gray-900);
	font-size: $font-size-md;
	font-family: $font-family-base;
	font-weight: $font-weight-base;
	line-height: $line-height-base;
}

option {
	color: var(--color-gray-900);
	font-size: $font-size-md;
	font-family: $font-family-base;
	font-weight: $font-weight-base;
	line-height: $line-height-base;
	text-transform: initial;
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
}

iframe {
	border: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

td,
th {
	padding: 0;
}

a {
	text-decoration: none;
}

[align] {
	text-align: initial;
}

router-outlet {
	display: contents;
}
