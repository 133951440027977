:root {
  --color-brand: #008fd5;
  --color-brand-rgb: 0, 143, 213;
  --color-brand-light: #00aaff;
  --color-brand-light-rgb: 0, 170, 255;
  --color-brand-contrast: #ffffff;
  --color-brand-contrast-rgb: 255, 255, 255;
  --color-success: #02b85d;
  --color-success-rgb: 2, 184, 93;
  --color-success-light: #00d169;
  --color-success-light-rgb: 0, 209, 105;
  --color-success-contrast: #ffffff;
  --color-success-contrast-rgb: 255, 255, 255;
  --color-warning: #f0b800;
  --color-warning-rgb: 240, 184, 0;
  --color-warning-light: #ffcf33;
  --color-warning-light-rgb: 255, 207, 51;
  --color-warning-contrast: #ffffff;
  --color-warning-contrast-rgb: 255, 255, 255;
  --color-danger: #ea484a;
  --color-danger-rgb: 234, 72, 74;
  --color-danger-light: #ff6164;
  --color-danger-light-rgb: 255, 97, 100;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255, 255, 255;
  --color-white: #ffffff;
  --color-white-rgb: 255, 255, 255;
  --color-gray-100: #f0f4f7;
  --color-gray-100-rgb: 240, 244, 247;
  --color-gray-300: #d8e0e6;
  --color-gray-300-rgb: 216, 224, 230;
  --color-gray-500: #abbecc;
  --color-gray-500-rgb: 171, 190, 204;
  --color-gray-700: #798fa3;
  --color-gray-700-rgb: 121, 143, 163;
  --color-gray-900: #4d4d4f;
  --color-gray-900-rgb: 77, 77, 79;
  --color-black: #000000;
  --color-black-rgb: 0, 0, 0;
  --ms-nav-size: 60px;
  --ms-spacing-x: 15px;
  --ms-spacing-y: 15px;
}
@media (min-width: 576px) {
  :root {
    --ms-spacing-y: 30px;
  }
}
@media (min-width: 992px) {
  :root {
    --ms-nav-size: 85px;
    --ms-spacing-x: 30px;
    --ms-spacing-y: 30px;
  }
}
@media (min-width: 1200px) {
  :root {
    --ms-nav-size: 275px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  color: var(--color-gray-900);
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

html,
body,
p,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding-left: 20px;
}

button,
input,
select,
textarea {
  margin: 0;
  color: var(--color-gray-900);
  font-size: inherit;
  font-family: inherit;
}

textarea {
  color: var(--color-gray-900);
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

option {
  color: var(--color-gray-900);
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  text-transform: initial;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

a {
  text-decoration: none;
}

[align] {
  text-align: initial;
}

router-outlet {
  display: contents;
}

html,
body {
  width: 100%;
  height: 100%;
}
@media (min-width: 992px) {
  html,
  body {
    height: calc(100vh - var(--vh-offset, 0));
  }
}

body {
  background-color: #f0f4f7;
}
body.ms-logged-out {
  background-color: #008fd5;
}
body.overlay-open {
  overflow: hidden !important;
}

@media (min-width: 992px) {
  * {
    scrollbar-width: var(--scrollbar-style, auto);
    scrollbar-color: var(--scrollbar-thumb-color, var(--color-gray-500)) var(--scrollbar-track-color, var(--color-gray-100));
  }
  ::-webkit-scrollbar {
    width: var(--scrollbar-size, 15px);
    height: var(--scrollbar-size, 15px);
    background-color: var(--scrollbar-track-color, var(--color-gray-100));
  }
  ::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track-color, var(--color-gray-100));
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color, var(--color-gray-500));
    border-radius: 999px;
    border: 4px solid var(--scrollbar-track-color, var(--color-gray-100));
  }
  ::-webkit-scrollbar-corner {
    background-color: var(--scrollbar-track-color, var(--color-gray-100));
  }
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Teko", sans-serif;
  font-weight: 700;
  font-style: normal;
  line-height: 1.2;
}

h1,
.h1,
.text-1 {
  font-size: 4.5rem;
}

h2,
.h2,
.text-2 {
  font-size: 3.5rem;
}

h3,
.h3,
.text-3 {
  font-size: 3rem;
}

h4,
.h4,
.text-4 {
  font-size: 2.5rem;
}

h5,
.h5,
.text-5 {
  font-size: 2.2rem;
}

h6,
.h6,
.text-6 {
  font-size: 2rem;
}

.text-lg {
  font-size: 1.8rem;
}

small,
.small,
.text-sm {
  font-size: 1.4rem;
}

.text-xs {
  font-size: 1.2rem;
}

.section {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.section:not(:first-child):not(.-pad) {
  margin-top: var(--ms-spacing-y);
}
.section:not(:last-child):not(.-pad) {
  margin-bottom: var(--ms-spacing-y);
}
.section.-pad {
  padding-top: var(--ms-spacing-y);
  padding-bottom: var(--ms-spacing-y);
}

.form {
  display: block;
}

form.form,
.form > form {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(var(--form-columns, 1), minmax(0, 1fr));
  gap: var(--form-gap-y, 20px) var(--form-gap-x, 20px);
}
form.form .form-required,
.form > form .form-required {
  grid-column: span var(--form-columns, 1);
}

.overlay-wrapper {
  display: contents !important;
}

.p-orderlist-list {
  padding-left: 0;
}

.bg-brand {
  background-color: #008fd5 !important;
}

.bg-success {
  background-color: #02b85d !important;
}

.bg-warning {
  background-color: #f0b800 !important;
}

.bg-danger {
  background-color: #ea484a !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-gray-100 {
  background-color: #f0f4f7 !important;
}

.bg-gray-300 {
  background-color: #d8e0e6 !important;
}

.bg-gray-500 {
  background-color: #abbecc !important;
}

.bg-gray-700 {
  background-color: #798fa3 !important;
}

.bg-gray-900 {
  background-color: #4d4d4f !important;
}

.bg-black {
  background-color: #000000 !important;
}

.d-none {
  display: none !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-initial {
  display: initial !important;
}

.d-revert {
  display: revert !important;
}

.d-contents {
  display: contents !important;
}

@media (min-width: 576px) {
  .d-none\:sm {
    display: none !important;
  }
  .d-inline-block\:sm {
    display: inline-block !important;
  }
  .d-block\:sm {
    display: block !important;
  }
  .d-flex\:sm {
    display: flex !important;
  }
  .d-inline-flex\:sm {
    display: inline-flex !important;
  }
  .d-initial\:sm {
    display: initial !important;
  }
  .d-revert\:sm {
    display: revert !important;
  }
  .d-contents\:sm {
    display: contents !important;
  }
}
@media (min-width: 768px) {
  .d-none\:md {
    display: none !important;
  }
  .d-inline-block\:md {
    display: inline-block !important;
  }
  .d-block\:md {
    display: block !important;
  }
  .d-flex\:md {
    display: flex !important;
  }
  .d-inline-flex\:md {
    display: inline-flex !important;
  }
  .d-initial\:md {
    display: initial !important;
  }
  .d-revert\:md {
    display: revert !important;
  }
  .d-contents\:md {
    display: contents !important;
  }
}
@media (min-width: 992px) {
  .d-none\:lg {
    display: none !important;
  }
  .d-inline-block\:lg {
    display: inline-block !important;
  }
  .d-block\:lg {
    display: block !important;
  }
  .d-flex\:lg {
    display: flex !important;
  }
  .d-inline-flex\:lg {
    display: inline-flex !important;
  }
  .d-initial\:lg {
    display: initial !important;
  }
  .d-revert\:lg {
    display: revert !important;
  }
  .d-contents\:lg {
    display: contents !important;
  }
}
@media (min-width: 1200px) {
  .d-none\:xl {
    display: none !important;
  }
  .d-inline-block\:xl {
    display: inline-block !important;
  }
  .d-block\:xl {
    display: block !important;
  }
  .d-flex\:xl {
    display: flex !important;
  }
  .d-inline-flex\:xl {
    display: inline-flex !important;
  }
  .d-initial\:xl {
    display: initial !important;
  }
  .d-revert\:xl {
    display: revert !important;
  }
  .d-contents\:xl {
    display: contents !important;
  }
}
@media (min-width: 1400px) {
  .d-none\:xxl {
    display: none !important;
  }
  .d-inline-block\:xxl {
    display: inline-block !important;
  }
  .d-block\:xxl {
    display: block !important;
  }
  .d-flex\:xxl {
    display: flex !important;
  }
  .d-inline-flex\:xxl {
    display: inline-flex !important;
  }
  .d-initial\:xxl {
    display: initial !important;
  }
  .d-revert\:xxl {
    display: revert !important;
  }
  .d-contents\:xxl {
    display: contents !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-self-start {
  justify-self: start !important;
}

.justify-self-end {
  justify-self: end !important;
}

.justify-self-center {
  justify-self: center !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-row\:sm {
    flex-direction: row !important;
  }
  .flex-column\:sm {
    flex-direction: column !important;
  }
  .flex-row-reverse\:sm {
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse\:sm {
    flex-direction: column-reverse !important;
  }
  .flex-wrap\:sm {
    flex-wrap: wrap !important;
  }
  .flex-nowrap\:sm {
    flex-wrap: nowrap !important;
  }
  .flex-shrink-0\:sm {
    flex-shrink: 0 !important;
  }
  .flex-shrink-1\:sm {
    flex-shrink: 1 !important;
  }
  .flex-grow-0\:sm {
    flex-grow: 0 !important;
  }
  .flex-grow-1\:sm {
    flex-grow: 1 !important;
  }
  .justify-content-start\:sm {
    justify-content: flex-start !important;
  }
  .justify-content-end\:sm {
    justify-content: flex-end !important;
  }
  .justify-content-center\:sm {
    justify-content: center !important;
  }
  .justify-content-between\:sm {
    justify-content: space-between !important;
  }
  .justify-content-around\:sm {
    justify-content: space-around !important;
  }
  .justify-self-start\:sm {
    justify-self: start !important;
  }
  .justify-self-end\:sm {
    justify-self: end !important;
  }
  .justify-self-center\:sm {
    justify-self: center !important;
  }
  .align-items-start\:sm {
    align-items: flex-start !important;
  }
  .align-items-end\:sm {
    align-items: flex-end !important;
  }
  .align-items-center\:sm {
    align-items: center !important;
  }
  .align-items-baseline\:sm {
    align-items: baseline !important;
  }
  .align-items-stretch\:sm {
    align-items: stretch !important;
  }
  .align-self-auto\:sm {
    align-self: auto !important;
  }
  .align-self-start\:sm {
    align-self: flex-start !important;
  }
  .align-self-end\:sm {
    align-self: flex-end !important;
  }
  .align-self-center\:sm {
    align-self: center !important;
  }
  .align-self-baseline\:sm {
    align-self: baseline !important;
  }
  .align-self-stretch\:sm {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-row\:md {
    flex-direction: row !important;
  }
  .flex-column\:md {
    flex-direction: column !important;
  }
  .flex-row-reverse\:md {
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse\:md {
    flex-direction: column-reverse !important;
  }
  .flex-wrap\:md {
    flex-wrap: wrap !important;
  }
  .flex-nowrap\:md {
    flex-wrap: nowrap !important;
  }
  .flex-shrink-0\:md {
    flex-shrink: 0 !important;
  }
  .flex-shrink-1\:md {
    flex-shrink: 1 !important;
  }
  .flex-grow-0\:md {
    flex-grow: 0 !important;
  }
  .flex-grow-1\:md {
    flex-grow: 1 !important;
  }
  .justify-content-start\:md {
    justify-content: flex-start !important;
  }
  .justify-content-end\:md {
    justify-content: flex-end !important;
  }
  .justify-content-center\:md {
    justify-content: center !important;
  }
  .justify-content-between\:md {
    justify-content: space-between !important;
  }
  .justify-content-around\:md {
    justify-content: space-around !important;
  }
  .justify-self-start\:md {
    justify-self: start !important;
  }
  .justify-self-end\:md {
    justify-self: end !important;
  }
  .justify-self-center\:md {
    justify-self: center !important;
  }
  .align-items-start\:md {
    align-items: flex-start !important;
  }
  .align-items-end\:md {
    align-items: flex-end !important;
  }
  .align-items-center\:md {
    align-items: center !important;
  }
  .align-items-baseline\:md {
    align-items: baseline !important;
  }
  .align-items-stretch\:md {
    align-items: stretch !important;
  }
  .align-self-auto\:md {
    align-self: auto !important;
  }
  .align-self-start\:md {
    align-self: flex-start !important;
  }
  .align-self-end\:md {
    align-self: flex-end !important;
  }
  .align-self-center\:md {
    align-self: center !important;
  }
  .align-self-baseline\:md {
    align-self: baseline !important;
  }
  .align-self-stretch\:md {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-row\:lg {
    flex-direction: row !important;
  }
  .flex-column\:lg {
    flex-direction: column !important;
  }
  .flex-row-reverse\:lg {
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse\:lg {
    flex-direction: column-reverse !important;
  }
  .flex-wrap\:lg {
    flex-wrap: wrap !important;
  }
  .flex-nowrap\:lg {
    flex-wrap: nowrap !important;
  }
  .flex-shrink-0\:lg {
    flex-shrink: 0 !important;
  }
  .flex-shrink-1\:lg {
    flex-shrink: 1 !important;
  }
  .flex-grow-0\:lg {
    flex-grow: 0 !important;
  }
  .flex-grow-1\:lg {
    flex-grow: 1 !important;
  }
  .justify-content-start\:lg {
    justify-content: flex-start !important;
  }
  .justify-content-end\:lg {
    justify-content: flex-end !important;
  }
  .justify-content-center\:lg {
    justify-content: center !important;
  }
  .justify-content-between\:lg {
    justify-content: space-between !important;
  }
  .justify-content-around\:lg {
    justify-content: space-around !important;
  }
  .justify-self-start\:lg {
    justify-self: start !important;
  }
  .justify-self-end\:lg {
    justify-self: end !important;
  }
  .justify-self-center\:lg {
    justify-self: center !important;
  }
  .align-items-start\:lg {
    align-items: flex-start !important;
  }
  .align-items-end\:lg {
    align-items: flex-end !important;
  }
  .align-items-center\:lg {
    align-items: center !important;
  }
  .align-items-baseline\:lg {
    align-items: baseline !important;
  }
  .align-items-stretch\:lg {
    align-items: stretch !important;
  }
  .align-self-auto\:lg {
    align-self: auto !important;
  }
  .align-self-start\:lg {
    align-self: flex-start !important;
  }
  .align-self-end\:lg {
    align-self: flex-end !important;
  }
  .align-self-center\:lg {
    align-self: center !important;
  }
  .align-self-baseline\:lg {
    align-self: baseline !important;
  }
  .align-self-stretch\:lg {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-row\:xl {
    flex-direction: row !important;
  }
  .flex-column\:xl {
    flex-direction: column !important;
  }
  .flex-row-reverse\:xl {
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse\:xl {
    flex-direction: column-reverse !important;
  }
  .flex-wrap\:xl {
    flex-wrap: wrap !important;
  }
  .flex-nowrap\:xl {
    flex-wrap: nowrap !important;
  }
  .flex-shrink-0\:xl {
    flex-shrink: 0 !important;
  }
  .flex-shrink-1\:xl {
    flex-shrink: 1 !important;
  }
  .flex-grow-0\:xl {
    flex-grow: 0 !important;
  }
  .flex-grow-1\:xl {
    flex-grow: 1 !important;
  }
  .justify-content-start\:xl {
    justify-content: flex-start !important;
  }
  .justify-content-end\:xl {
    justify-content: flex-end !important;
  }
  .justify-content-center\:xl {
    justify-content: center !important;
  }
  .justify-content-between\:xl {
    justify-content: space-between !important;
  }
  .justify-content-around\:xl {
    justify-content: space-around !important;
  }
  .justify-self-start\:xl {
    justify-self: start !important;
  }
  .justify-self-end\:xl {
    justify-self: end !important;
  }
  .justify-self-center\:xl {
    justify-self: center !important;
  }
  .align-items-start\:xl {
    align-items: flex-start !important;
  }
  .align-items-end\:xl {
    align-items: flex-end !important;
  }
  .align-items-center\:xl {
    align-items: center !important;
  }
  .align-items-baseline\:xl {
    align-items: baseline !important;
  }
  .align-items-stretch\:xl {
    align-items: stretch !important;
  }
  .align-self-auto\:xl {
    align-self: auto !important;
  }
  .align-self-start\:xl {
    align-self: flex-start !important;
  }
  .align-self-end\:xl {
    align-self: flex-end !important;
  }
  .align-self-center\:xl {
    align-self: center !important;
  }
  .align-self-baseline\:xl {
    align-self: baseline !important;
  }
  .align-self-stretch\:xl {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-row\:xxl {
    flex-direction: row !important;
  }
  .flex-column\:xxl {
    flex-direction: column !important;
  }
  .flex-row-reverse\:xxl {
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse\:xxl {
    flex-direction: column-reverse !important;
  }
  .flex-wrap\:xxl {
    flex-wrap: wrap !important;
  }
  .flex-nowrap\:xxl {
    flex-wrap: nowrap !important;
  }
  .flex-shrink-0\:xxl {
    flex-shrink: 0 !important;
  }
  .flex-shrink-1\:xxl {
    flex-shrink: 1 !important;
  }
  .flex-grow-0\:xxl {
    flex-grow: 0 !important;
  }
  .flex-grow-1\:xxl {
    flex-grow: 1 !important;
  }
  .justify-content-start\:xxl {
    justify-content: flex-start !important;
  }
  .justify-content-end\:xxl {
    justify-content: flex-end !important;
  }
  .justify-content-center\:xxl {
    justify-content: center !important;
  }
  .justify-content-between\:xxl {
    justify-content: space-between !important;
  }
  .justify-content-around\:xxl {
    justify-content: space-around !important;
  }
  .justify-self-start\:xxl {
    justify-self: start !important;
  }
  .justify-self-end\:xxl {
    justify-self: end !important;
  }
  .justify-self-center\:xxl {
    justify-self: center !important;
  }
  .align-items-start\:xxl {
    align-items: flex-start !important;
  }
  .align-items-end\:xxl {
    align-items: flex-end !important;
  }
  .align-items-center\:xxl {
    align-items: center !important;
  }
  .align-items-baseline\:xxl {
    align-items: baseline !important;
  }
  .align-items-stretch\:xxl {
    align-items: stretch !important;
  }
  .align-self-auto\:xxl {
    align-self: auto !important;
  }
  .align-self-start\:xxl {
    align-self: flex-start !important;
  }
  .align-self-end\:xxl {
    align-self: flex-end !important;
  }
  .align-self-center\:xxl {
    align-self: center !important;
  }
  .align-self-baseline\:xxl {
    align-self: baseline !important;
  }
  .align-self-stretch\:xxl {
    align-self: stretch !important;
  }
}
.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 5px !important;
}

.mt-1,
.my-1 {
  margin-top: 5px !important;
}

.mr-1,
.mx-1 {
  margin-right: 5px !important;
}

.mb-1,
.my-1 {
  margin-bottom: 5px !important;
}

.ml-1,
.mx-1 {
  margin-left: 5px !important;
}

.m-2 {
  margin: 10px !important;
}

.mt-2,
.my-2 {
  margin-top: 10px !important;
}

.mr-2,
.mx-2 {
  margin-right: 10px !important;
}

.mb-2,
.my-2 {
  margin-bottom: 10px !important;
}

.ml-2,
.mx-2 {
  margin-left: 10px !important;
}

.m-3 {
  margin: 15px !important;
}

.mt-3,
.my-3 {
  margin-top: 15px !important;
}

.mr-3,
.mx-3 {
  margin-right: 15px !important;
}

.mb-3,
.my-3 {
  margin-bottom: 15px !important;
}

.ml-3,
.mx-3 {
  margin-left: 15px !important;
}

.m-4 {
  margin: 20px !important;
}

.mt-4,
.my-4 {
  margin-top: 20px !important;
}

.mr-4,
.mx-4 {
  margin-right: 20px !important;
}

.mb-4,
.my-4 {
  margin-bottom: 20px !important;
}

.ml-4,
.mx-4 {
  margin-left: 20px !important;
}

.m-5 {
  margin: 25px !important;
}

.mt-5,
.my-5 {
  margin-top: 25px !important;
}

.mr-5,
.mx-5 {
  margin-right: 25px !important;
}

.mb-5,
.my-5 {
  margin-bottom: 25px !important;
}

.ml-5,
.mx-5 {
  margin-left: 25px !important;
}

.m-6 {
  margin: 30px !important;
}

.mt-6,
.my-6 {
  margin-top: 30px !important;
}

.mr-6,
.mx-6 {
  margin-right: 30px !important;
}

.mb-6,
.my-6 {
  margin-bottom: 30px !important;
}

.ml-6,
.mx-6 {
  margin-left: 30px !important;
}

.m-7 {
  margin: 35px !important;
}

.mt-7,
.my-7 {
  margin-top: 35px !important;
}

.mr-7,
.mx-7 {
  margin-right: 35px !important;
}

.mb-7,
.my-7 {
  margin-bottom: 35px !important;
}

.ml-7,
.mx-7 {
  margin-left: 35px !important;
}

.m-8 {
  margin: 40px !important;
}

.mt-8,
.my-8 {
  margin-top: 40px !important;
}

.mr-8,
.mx-8 {
  margin-right: 40px !important;
}

.mb-8,
.my-8 {
  margin-bottom: 40px !important;
}

.ml-8,
.mx-8 {
  margin-left: 40px !important;
}

.m-9 {
  margin: 45px !important;
}

.mt-9,
.my-9 {
  margin-top: 45px !important;
}

.mr-9,
.mx-9 {
  margin-right: 45px !important;
}

.mb-9,
.my-9 {
  margin-bottom: 45px !important;
}

.ml-9,
.mx-9 {
  margin-left: 45px !important;
}

.m-10 {
  margin: 50px !important;
}

.mt-10,
.my-10 {
  margin-top: 50px !important;
}

.mr-10,
.mx-10 {
  margin-right: 50px !important;
}

.mb-10,
.my-10 {
  margin-bottom: 50px !important;
}

.ml-10,
.mx-10 {
  margin-left: 50px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 5px !important;
}

.pt-1,
.py-1 {
  padding-top: 5px !important;
}

.pr-1,
.px-1 {
  padding-right: 5px !important;
}

.pb-1,
.py-1 {
  padding-bottom: 5px !important;
}

.pl-1,
.px-1 {
  padding-left: 5px !important;
}

.p-2 {
  padding: 10px !important;
}

.pt-2,
.py-2 {
  padding-top: 10px !important;
}

.pr-2,
.px-2 {
  padding-right: 10px !important;
}

.pb-2,
.py-2 {
  padding-bottom: 10px !important;
}

.pl-2,
.px-2 {
  padding-left: 10px !important;
}

.p-3 {
  padding: 15px !important;
}

.pt-3,
.py-3 {
  padding-top: 15px !important;
}

.pr-3,
.px-3 {
  padding-right: 15px !important;
}

.pb-3,
.py-3 {
  padding-bottom: 15px !important;
}

.pl-3,
.px-3 {
  padding-left: 15px !important;
}

.p-4 {
  padding: 20px !important;
}

.pt-4,
.py-4 {
  padding-top: 20px !important;
}

.pr-4,
.px-4 {
  padding-right: 20px !important;
}

.pb-4,
.py-4 {
  padding-bottom: 20px !important;
}

.pl-4,
.px-4 {
  padding-left: 20px !important;
}

.p-5 {
  padding: 25px !important;
}

.pt-5,
.py-5 {
  padding-top: 25px !important;
}

.pr-5,
.px-5 {
  padding-right: 25px !important;
}

.pb-5,
.py-5 {
  padding-bottom: 25px !important;
}

.pl-5,
.px-5 {
  padding-left: 25px !important;
}

.p-6 {
  padding: 30px !important;
}

.pt-6,
.py-6 {
  padding-top: 30px !important;
}

.pr-6,
.px-6 {
  padding-right: 30px !important;
}

.pb-6,
.py-6 {
  padding-bottom: 30px !important;
}

.pl-6,
.px-6 {
  padding-left: 30px !important;
}

.p-7 {
  padding: 35px !important;
}

.pt-7,
.py-7 {
  padding-top: 35px !important;
}

.pr-7,
.px-7 {
  padding-right: 35px !important;
}

.pb-7,
.py-7 {
  padding-bottom: 35px !important;
}

.pl-7,
.px-7 {
  padding-left: 35px !important;
}

.p-8 {
  padding: 40px !important;
}

.pt-8,
.py-8 {
  padding-top: 40px !important;
}

.pr-8,
.px-8 {
  padding-right: 40px !important;
}

.pb-8,
.py-8 {
  padding-bottom: 40px !important;
}

.pl-8,
.px-8 {
  padding-left: 40px !important;
}

.p-9 {
  padding: 45px !important;
}

.pt-9,
.py-9 {
  padding-top: 45px !important;
}

.pr-9,
.px-9 {
  padding-right: 45px !important;
}

.pb-9,
.py-9 {
  padding-bottom: 45px !important;
}

.pl-9,
.px-9 {
  padding-left: 45px !important;
}

.p-10 {
  padding: 50px !important;
}

.pt-10,
.py-10 {
  padding-top: 50px !important;
}

.pr-10,
.px-10 {
  padding-right: 50px !important;
}

.pb-10,
.py-10 {
  padding-bottom: 50px !important;
}

.pl-10,
.px-10 {
  padding-left: 50px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-0\:sm {
    margin: 0 !important;
  }
  .mt-0\:sm,
  .my-0\:sm {
    margin-top: 0 !important;
  }
  .mr-0\:sm,
  .mx-0\:sm {
    margin-right: 0 !important;
  }
  .mb-0\:sm,
  .my-0\:sm {
    margin-bottom: 0 !important;
  }
  .ml-0\:sm,
  .mx-0\:sm {
    margin-left: 0 !important;
  }
  .m-1\:sm {
    margin: 5px !important;
  }
  .mt-1\:sm,
  .my-1\:sm {
    margin-top: 5px !important;
  }
  .mr-1\:sm,
  .mx-1\:sm {
    margin-right: 5px !important;
  }
  .mb-1\:sm,
  .my-1\:sm {
    margin-bottom: 5px !important;
  }
  .ml-1\:sm,
  .mx-1\:sm {
    margin-left: 5px !important;
  }
  .m-2\:sm {
    margin: 10px !important;
  }
  .mt-2\:sm,
  .my-2\:sm {
    margin-top: 10px !important;
  }
  .mr-2\:sm,
  .mx-2\:sm {
    margin-right: 10px !important;
  }
  .mb-2\:sm,
  .my-2\:sm {
    margin-bottom: 10px !important;
  }
  .ml-2\:sm,
  .mx-2\:sm {
    margin-left: 10px !important;
  }
  .m-3\:sm {
    margin: 15px !important;
  }
  .mt-3\:sm,
  .my-3\:sm {
    margin-top: 15px !important;
  }
  .mr-3\:sm,
  .mx-3\:sm {
    margin-right: 15px !important;
  }
  .mb-3\:sm,
  .my-3\:sm {
    margin-bottom: 15px !important;
  }
  .ml-3\:sm,
  .mx-3\:sm {
    margin-left: 15px !important;
  }
  .m-4\:sm {
    margin: 20px !important;
  }
  .mt-4\:sm,
  .my-4\:sm {
    margin-top: 20px !important;
  }
  .mr-4\:sm,
  .mx-4\:sm {
    margin-right: 20px !important;
  }
  .mb-4\:sm,
  .my-4\:sm {
    margin-bottom: 20px !important;
  }
  .ml-4\:sm,
  .mx-4\:sm {
    margin-left: 20px !important;
  }
  .m-5\:sm {
    margin: 25px !important;
  }
  .mt-5\:sm,
  .my-5\:sm {
    margin-top: 25px !important;
  }
  .mr-5\:sm,
  .mx-5\:sm {
    margin-right: 25px !important;
  }
  .mb-5\:sm,
  .my-5\:sm {
    margin-bottom: 25px !important;
  }
  .ml-5\:sm,
  .mx-5\:sm {
    margin-left: 25px !important;
  }
  .m-6\:sm {
    margin: 30px !important;
  }
  .mt-6\:sm,
  .my-6\:sm {
    margin-top: 30px !important;
  }
  .mr-6\:sm,
  .mx-6\:sm {
    margin-right: 30px !important;
  }
  .mb-6\:sm,
  .my-6\:sm {
    margin-bottom: 30px !important;
  }
  .ml-6\:sm,
  .mx-6\:sm {
    margin-left: 30px !important;
  }
  .m-7\:sm {
    margin: 35px !important;
  }
  .mt-7\:sm,
  .my-7\:sm {
    margin-top: 35px !important;
  }
  .mr-7\:sm,
  .mx-7\:sm {
    margin-right: 35px !important;
  }
  .mb-7\:sm,
  .my-7\:sm {
    margin-bottom: 35px !important;
  }
  .ml-7\:sm,
  .mx-7\:sm {
    margin-left: 35px !important;
  }
  .m-8\:sm {
    margin: 40px !important;
  }
  .mt-8\:sm,
  .my-8\:sm {
    margin-top: 40px !important;
  }
  .mr-8\:sm,
  .mx-8\:sm {
    margin-right: 40px !important;
  }
  .mb-8\:sm,
  .my-8\:sm {
    margin-bottom: 40px !important;
  }
  .ml-8\:sm,
  .mx-8\:sm {
    margin-left: 40px !important;
  }
  .m-9\:sm {
    margin: 45px !important;
  }
  .mt-9\:sm,
  .my-9\:sm {
    margin-top: 45px !important;
  }
  .mr-9\:sm,
  .mx-9\:sm {
    margin-right: 45px !important;
  }
  .mb-9\:sm,
  .my-9\:sm {
    margin-bottom: 45px !important;
  }
  .ml-9\:sm,
  .mx-9\:sm {
    margin-left: 45px !important;
  }
  .m-10\:sm {
    margin: 50px !important;
  }
  .mt-10\:sm,
  .my-10\:sm {
    margin-top: 50px !important;
  }
  .mr-10\:sm,
  .mx-10\:sm {
    margin-right: 50px !important;
  }
  .mb-10\:sm,
  .my-10\:sm {
    margin-bottom: 50px !important;
  }
  .ml-10\:sm,
  .mx-10\:sm {
    margin-left: 50px !important;
  }
  .p-0\:sm {
    padding: 0 !important;
  }
  .pt-0\:sm,
  .py-0\:sm {
    padding-top: 0 !important;
  }
  .pr-0\:sm,
  .px-0\:sm {
    padding-right: 0 !important;
  }
  .pb-0\:sm,
  .py-0\:sm {
    padding-bottom: 0 !important;
  }
  .pl-0\:sm,
  .px-0\:sm {
    padding-left: 0 !important;
  }
  .p-1\:sm {
    padding: 5px !important;
  }
  .pt-1\:sm,
  .py-1\:sm {
    padding-top: 5px !important;
  }
  .pr-1\:sm,
  .px-1\:sm {
    padding-right: 5px !important;
  }
  .pb-1\:sm,
  .py-1\:sm {
    padding-bottom: 5px !important;
  }
  .pl-1\:sm,
  .px-1\:sm {
    padding-left: 5px !important;
  }
  .p-2\:sm {
    padding: 10px !important;
  }
  .pt-2\:sm,
  .py-2\:sm {
    padding-top: 10px !important;
  }
  .pr-2\:sm,
  .px-2\:sm {
    padding-right: 10px !important;
  }
  .pb-2\:sm,
  .py-2\:sm {
    padding-bottom: 10px !important;
  }
  .pl-2\:sm,
  .px-2\:sm {
    padding-left: 10px !important;
  }
  .p-3\:sm {
    padding: 15px !important;
  }
  .pt-3\:sm,
  .py-3\:sm {
    padding-top: 15px !important;
  }
  .pr-3\:sm,
  .px-3\:sm {
    padding-right: 15px !important;
  }
  .pb-3\:sm,
  .py-3\:sm {
    padding-bottom: 15px !important;
  }
  .pl-3\:sm,
  .px-3\:sm {
    padding-left: 15px !important;
  }
  .p-4\:sm {
    padding: 20px !important;
  }
  .pt-4\:sm,
  .py-4\:sm {
    padding-top: 20px !important;
  }
  .pr-4\:sm,
  .px-4\:sm {
    padding-right: 20px !important;
  }
  .pb-4\:sm,
  .py-4\:sm {
    padding-bottom: 20px !important;
  }
  .pl-4\:sm,
  .px-4\:sm {
    padding-left: 20px !important;
  }
  .p-5\:sm {
    padding: 25px !important;
  }
  .pt-5\:sm,
  .py-5\:sm {
    padding-top: 25px !important;
  }
  .pr-5\:sm,
  .px-5\:sm {
    padding-right: 25px !important;
  }
  .pb-5\:sm,
  .py-5\:sm {
    padding-bottom: 25px !important;
  }
  .pl-5\:sm,
  .px-5\:sm {
    padding-left: 25px !important;
  }
  .p-6\:sm {
    padding: 30px !important;
  }
  .pt-6\:sm,
  .py-6\:sm {
    padding-top: 30px !important;
  }
  .pr-6\:sm,
  .px-6\:sm {
    padding-right: 30px !important;
  }
  .pb-6\:sm,
  .py-6\:sm {
    padding-bottom: 30px !important;
  }
  .pl-6\:sm,
  .px-6\:sm {
    padding-left: 30px !important;
  }
  .p-7\:sm {
    padding: 35px !important;
  }
  .pt-7\:sm,
  .py-7\:sm {
    padding-top: 35px !important;
  }
  .pr-7\:sm,
  .px-7\:sm {
    padding-right: 35px !important;
  }
  .pb-7\:sm,
  .py-7\:sm {
    padding-bottom: 35px !important;
  }
  .pl-7\:sm,
  .px-7\:sm {
    padding-left: 35px !important;
  }
  .p-8\:sm {
    padding: 40px !important;
  }
  .pt-8\:sm,
  .py-8\:sm {
    padding-top: 40px !important;
  }
  .pr-8\:sm,
  .px-8\:sm {
    padding-right: 40px !important;
  }
  .pb-8\:sm,
  .py-8\:sm {
    padding-bottom: 40px !important;
  }
  .pl-8\:sm,
  .px-8\:sm {
    padding-left: 40px !important;
  }
  .p-9\:sm {
    padding: 45px !important;
  }
  .pt-9\:sm,
  .py-9\:sm {
    padding-top: 45px !important;
  }
  .pr-9\:sm,
  .px-9\:sm {
    padding-right: 45px !important;
  }
  .pb-9\:sm,
  .py-9\:sm {
    padding-bottom: 45px !important;
  }
  .pl-9\:sm,
  .px-9\:sm {
    padding-left: 45px !important;
  }
  .p-10\:sm {
    padding: 50px !important;
  }
  .pt-10\:sm,
  .py-10\:sm {
    padding-top: 50px !important;
  }
  .pr-10\:sm,
  .px-10\:sm {
    padding-right: 50px !important;
  }
  .pb-10\:sm,
  .py-10\:sm {
    padding-bottom: 50px !important;
  }
  .pl-10\:sm,
  .px-10\:sm {
    padding-left: 50px !important;
  }
  .m-auto\:sm {
    margin: auto !important;
  }
  .mt-auto\:sm,
  .my-auto\:sm {
    margin-top: auto !important;
  }
  .mr-auto\:sm,
  .mx-auto\:sm {
    margin-right: auto !important;
  }
  .mb-auto\:sm,
  .my-auto\:sm {
    margin-bottom: auto !important;
  }
  .ml-auto\:sm,
  .mx-auto\:sm {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-0\:md {
    margin: 0 !important;
  }
  .mt-0\:md,
  .my-0\:md {
    margin-top: 0 !important;
  }
  .mr-0\:md,
  .mx-0\:md {
    margin-right: 0 !important;
  }
  .mb-0\:md,
  .my-0\:md {
    margin-bottom: 0 !important;
  }
  .ml-0\:md,
  .mx-0\:md {
    margin-left: 0 !important;
  }
  .m-1\:md {
    margin: 5px !important;
  }
  .mt-1\:md,
  .my-1\:md {
    margin-top: 5px !important;
  }
  .mr-1\:md,
  .mx-1\:md {
    margin-right: 5px !important;
  }
  .mb-1\:md,
  .my-1\:md {
    margin-bottom: 5px !important;
  }
  .ml-1\:md,
  .mx-1\:md {
    margin-left: 5px !important;
  }
  .m-2\:md {
    margin: 10px !important;
  }
  .mt-2\:md,
  .my-2\:md {
    margin-top: 10px !important;
  }
  .mr-2\:md,
  .mx-2\:md {
    margin-right: 10px !important;
  }
  .mb-2\:md,
  .my-2\:md {
    margin-bottom: 10px !important;
  }
  .ml-2\:md,
  .mx-2\:md {
    margin-left: 10px !important;
  }
  .m-3\:md {
    margin: 15px !important;
  }
  .mt-3\:md,
  .my-3\:md {
    margin-top: 15px !important;
  }
  .mr-3\:md,
  .mx-3\:md {
    margin-right: 15px !important;
  }
  .mb-3\:md,
  .my-3\:md {
    margin-bottom: 15px !important;
  }
  .ml-3\:md,
  .mx-3\:md {
    margin-left: 15px !important;
  }
  .m-4\:md {
    margin: 20px !important;
  }
  .mt-4\:md,
  .my-4\:md {
    margin-top: 20px !important;
  }
  .mr-4\:md,
  .mx-4\:md {
    margin-right: 20px !important;
  }
  .mb-4\:md,
  .my-4\:md {
    margin-bottom: 20px !important;
  }
  .ml-4\:md,
  .mx-4\:md {
    margin-left: 20px !important;
  }
  .m-5\:md {
    margin: 25px !important;
  }
  .mt-5\:md,
  .my-5\:md {
    margin-top: 25px !important;
  }
  .mr-5\:md,
  .mx-5\:md {
    margin-right: 25px !important;
  }
  .mb-5\:md,
  .my-5\:md {
    margin-bottom: 25px !important;
  }
  .ml-5\:md,
  .mx-5\:md {
    margin-left: 25px !important;
  }
  .m-6\:md {
    margin: 30px !important;
  }
  .mt-6\:md,
  .my-6\:md {
    margin-top: 30px !important;
  }
  .mr-6\:md,
  .mx-6\:md {
    margin-right: 30px !important;
  }
  .mb-6\:md,
  .my-6\:md {
    margin-bottom: 30px !important;
  }
  .ml-6\:md,
  .mx-6\:md {
    margin-left: 30px !important;
  }
  .m-7\:md {
    margin: 35px !important;
  }
  .mt-7\:md,
  .my-7\:md {
    margin-top: 35px !important;
  }
  .mr-7\:md,
  .mx-7\:md {
    margin-right: 35px !important;
  }
  .mb-7\:md,
  .my-7\:md {
    margin-bottom: 35px !important;
  }
  .ml-7\:md,
  .mx-7\:md {
    margin-left: 35px !important;
  }
  .m-8\:md {
    margin: 40px !important;
  }
  .mt-8\:md,
  .my-8\:md {
    margin-top: 40px !important;
  }
  .mr-8\:md,
  .mx-8\:md {
    margin-right: 40px !important;
  }
  .mb-8\:md,
  .my-8\:md {
    margin-bottom: 40px !important;
  }
  .ml-8\:md,
  .mx-8\:md {
    margin-left: 40px !important;
  }
  .m-9\:md {
    margin: 45px !important;
  }
  .mt-9\:md,
  .my-9\:md {
    margin-top: 45px !important;
  }
  .mr-9\:md,
  .mx-9\:md {
    margin-right: 45px !important;
  }
  .mb-9\:md,
  .my-9\:md {
    margin-bottom: 45px !important;
  }
  .ml-9\:md,
  .mx-9\:md {
    margin-left: 45px !important;
  }
  .m-10\:md {
    margin: 50px !important;
  }
  .mt-10\:md,
  .my-10\:md {
    margin-top: 50px !important;
  }
  .mr-10\:md,
  .mx-10\:md {
    margin-right: 50px !important;
  }
  .mb-10\:md,
  .my-10\:md {
    margin-bottom: 50px !important;
  }
  .ml-10\:md,
  .mx-10\:md {
    margin-left: 50px !important;
  }
  .p-0\:md {
    padding: 0 !important;
  }
  .pt-0\:md,
  .py-0\:md {
    padding-top: 0 !important;
  }
  .pr-0\:md,
  .px-0\:md {
    padding-right: 0 !important;
  }
  .pb-0\:md,
  .py-0\:md {
    padding-bottom: 0 !important;
  }
  .pl-0\:md,
  .px-0\:md {
    padding-left: 0 !important;
  }
  .p-1\:md {
    padding: 5px !important;
  }
  .pt-1\:md,
  .py-1\:md {
    padding-top: 5px !important;
  }
  .pr-1\:md,
  .px-1\:md {
    padding-right: 5px !important;
  }
  .pb-1\:md,
  .py-1\:md {
    padding-bottom: 5px !important;
  }
  .pl-1\:md,
  .px-1\:md {
    padding-left: 5px !important;
  }
  .p-2\:md {
    padding: 10px !important;
  }
  .pt-2\:md,
  .py-2\:md {
    padding-top: 10px !important;
  }
  .pr-2\:md,
  .px-2\:md {
    padding-right: 10px !important;
  }
  .pb-2\:md,
  .py-2\:md {
    padding-bottom: 10px !important;
  }
  .pl-2\:md,
  .px-2\:md {
    padding-left: 10px !important;
  }
  .p-3\:md {
    padding: 15px !important;
  }
  .pt-3\:md,
  .py-3\:md {
    padding-top: 15px !important;
  }
  .pr-3\:md,
  .px-3\:md {
    padding-right: 15px !important;
  }
  .pb-3\:md,
  .py-3\:md {
    padding-bottom: 15px !important;
  }
  .pl-3\:md,
  .px-3\:md {
    padding-left: 15px !important;
  }
  .p-4\:md {
    padding: 20px !important;
  }
  .pt-4\:md,
  .py-4\:md {
    padding-top: 20px !important;
  }
  .pr-4\:md,
  .px-4\:md {
    padding-right: 20px !important;
  }
  .pb-4\:md,
  .py-4\:md {
    padding-bottom: 20px !important;
  }
  .pl-4\:md,
  .px-4\:md {
    padding-left: 20px !important;
  }
  .p-5\:md {
    padding: 25px !important;
  }
  .pt-5\:md,
  .py-5\:md {
    padding-top: 25px !important;
  }
  .pr-5\:md,
  .px-5\:md {
    padding-right: 25px !important;
  }
  .pb-5\:md,
  .py-5\:md {
    padding-bottom: 25px !important;
  }
  .pl-5\:md,
  .px-5\:md {
    padding-left: 25px !important;
  }
  .p-6\:md {
    padding: 30px !important;
  }
  .pt-6\:md,
  .py-6\:md {
    padding-top: 30px !important;
  }
  .pr-6\:md,
  .px-6\:md {
    padding-right: 30px !important;
  }
  .pb-6\:md,
  .py-6\:md {
    padding-bottom: 30px !important;
  }
  .pl-6\:md,
  .px-6\:md {
    padding-left: 30px !important;
  }
  .p-7\:md {
    padding: 35px !important;
  }
  .pt-7\:md,
  .py-7\:md {
    padding-top: 35px !important;
  }
  .pr-7\:md,
  .px-7\:md {
    padding-right: 35px !important;
  }
  .pb-7\:md,
  .py-7\:md {
    padding-bottom: 35px !important;
  }
  .pl-7\:md,
  .px-7\:md {
    padding-left: 35px !important;
  }
  .p-8\:md {
    padding: 40px !important;
  }
  .pt-8\:md,
  .py-8\:md {
    padding-top: 40px !important;
  }
  .pr-8\:md,
  .px-8\:md {
    padding-right: 40px !important;
  }
  .pb-8\:md,
  .py-8\:md {
    padding-bottom: 40px !important;
  }
  .pl-8\:md,
  .px-8\:md {
    padding-left: 40px !important;
  }
  .p-9\:md {
    padding: 45px !important;
  }
  .pt-9\:md,
  .py-9\:md {
    padding-top: 45px !important;
  }
  .pr-9\:md,
  .px-9\:md {
    padding-right: 45px !important;
  }
  .pb-9\:md,
  .py-9\:md {
    padding-bottom: 45px !important;
  }
  .pl-9\:md,
  .px-9\:md {
    padding-left: 45px !important;
  }
  .p-10\:md {
    padding: 50px !important;
  }
  .pt-10\:md,
  .py-10\:md {
    padding-top: 50px !important;
  }
  .pr-10\:md,
  .px-10\:md {
    padding-right: 50px !important;
  }
  .pb-10\:md,
  .py-10\:md {
    padding-bottom: 50px !important;
  }
  .pl-10\:md,
  .px-10\:md {
    padding-left: 50px !important;
  }
  .m-auto\:md {
    margin: auto !important;
  }
  .mt-auto\:md,
  .my-auto\:md {
    margin-top: auto !important;
  }
  .mr-auto\:md,
  .mx-auto\:md {
    margin-right: auto !important;
  }
  .mb-auto\:md,
  .my-auto\:md {
    margin-bottom: auto !important;
  }
  .ml-auto\:md,
  .mx-auto\:md {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-0\:lg {
    margin: 0 !important;
  }
  .mt-0\:lg,
  .my-0\:lg {
    margin-top: 0 !important;
  }
  .mr-0\:lg,
  .mx-0\:lg {
    margin-right: 0 !important;
  }
  .mb-0\:lg,
  .my-0\:lg {
    margin-bottom: 0 !important;
  }
  .ml-0\:lg,
  .mx-0\:lg {
    margin-left: 0 !important;
  }
  .m-1\:lg {
    margin: 5px !important;
  }
  .mt-1\:lg,
  .my-1\:lg {
    margin-top: 5px !important;
  }
  .mr-1\:lg,
  .mx-1\:lg {
    margin-right: 5px !important;
  }
  .mb-1\:lg,
  .my-1\:lg {
    margin-bottom: 5px !important;
  }
  .ml-1\:lg,
  .mx-1\:lg {
    margin-left: 5px !important;
  }
  .m-2\:lg {
    margin: 10px !important;
  }
  .mt-2\:lg,
  .my-2\:lg {
    margin-top: 10px !important;
  }
  .mr-2\:lg,
  .mx-2\:lg {
    margin-right: 10px !important;
  }
  .mb-2\:lg,
  .my-2\:lg {
    margin-bottom: 10px !important;
  }
  .ml-2\:lg,
  .mx-2\:lg {
    margin-left: 10px !important;
  }
  .m-3\:lg {
    margin: 15px !important;
  }
  .mt-3\:lg,
  .my-3\:lg {
    margin-top: 15px !important;
  }
  .mr-3\:lg,
  .mx-3\:lg {
    margin-right: 15px !important;
  }
  .mb-3\:lg,
  .my-3\:lg {
    margin-bottom: 15px !important;
  }
  .ml-3\:lg,
  .mx-3\:lg {
    margin-left: 15px !important;
  }
  .m-4\:lg {
    margin: 20px !important;
  }
  .mt-4\:lg,
  .my-4\:lg {
    margin-top: 20px !important;
  }
  .mr-4\:lg,
  .mx-4\:lg {
    margin-right: 20px !important;
  }
  .mb-4\:lg,
  .my-4\:lg {
    margin-bottom: 20px !important;
  }
  .ml-4\:lg,
  .mx-4\:lg {
    margin-left: 20px !important;
  }
  .m-5\:lg {
    margin: 25px !important;
  }
  .mt-5\:lg,
  .my-5\:lg {
    margin-top: 25px !important;
  }
  .mr-5\:lg,
  .mx-5\:lg {
    margin-right: 25px !important;
  }
  .mb-5\:lg,
  .my-5\:lg {
    margin-bottom: 25px !important;
  }
  .ml-5\:lg,
  .mx-5\:lg {
    margin-left: 25px !important;
  }
  .m-6\:lg {
    margin: 30px !important;
  }
  .mt-6\:lg,
  .my-6\:lg {
    margin-top: 30px !important;
  }
  .mr-6\:lg,
  .mx-6\:lg {
    margin-right: 30px !important;
  }
  .mb-6\:lg,
  .my-6\:lg {
    margin-bottom: 30px !important;
  }
  .ml-6\:lg,
  .mx-6\:lg {
    margin-left: 30px !important;
  }
  .m-7\:lg {
    margin: 35px !important;
  }
  .mt-7\:lg,
  .my-7\:lg {
    margin-top: 35px !important;
  }
  .mr-7\:lg,
  .mx-7\:lg {
    margin-right: 35px !important;
  }
  .mb-7\:lg,
  .my-7\:lg {
    margin-bottom: 35px !important;
  }
  .ml-7\:lg,
  .mx-7\:lg {
    margin-left: 35px !important;
  }
  .m-8\:lg {
    margin: 40px !important;
  }
  .mt-8\:lg,
  .my-8\:lg {
    margin-top: 40px !important;
  }
  .mr-8\:lg,
  .mx-8\:lg {
    margin-right: 40px !important;
  }
  .mb-8\:lg,
  .my-8\:lg {
    margin-bottom: 40px !important;
  }
  .ml-8\:lg,
  .mx-8\:lg {
    margin-left: 40px !important;
  }
  .m-9\:lg {
    margin: 45px !important;
  }
  .mt-9\:lg,
  .my-9\:lg {
    margin-top: 45px !important;
  }
  .mr-9\:lg,
  .mx-9\:lg {
    margin-right: 45px !important;
  }
  .mb-9\:lg,
  .my-9\:lg {
    margin-bottom: 45px !important;
  }
  .ml-9\:lg,
  .mx-9\:lg {
    margin-left: 45px !important;
  }
  .m-10\:lg {
    margin: 50px !important;
  }
  .mt-10\:lg,
  .my-10\:lg {
    margin-top: 50px !important;
  }
  .mr-10\:lg,
  .mx-10\:lg {
    margin-right: 50px !important;
  }
  .mb-10\:lg,
  .my-10\:lg {
    margin-bottom: 50px !important;
  }
  .ml-10\:lg,
  .mx-10\:lg {
    margin-left: 50px !important;
  }
  .p-0\:lg {
    padding: 0 !important;
  }
  .pt-0\:lg,
  .py-0\:lg {
    padding-top: 0 !important;
  }
  .pr-0\:lg,
  .px-0\:lg {
    padding-right: 0 !important;
  }
  .pb-0\:lg,
  .py-0\:lg {
    padding-bottom: 0 !important;
  }
  .pl-0\:lg,
  .px-0\:lg {
    padding-left: 0 !important;
  }
  .p-1\:lg {
    padding: 5px !important;
  }
  .pt-1\:lg,
  .py-1\:lg {
    padding-top: 5px !important;
  }
  .pr-1\:lg,
  .px-1\:lg {
    padding-right: 5px !important;
  }
  .pb-1\:lg,
  .py-1\:lg {
    padding-bottom: 5px !important;
  }
  .pl-1\:lg,
  .px-1\:lg {
    padding-left: 5px !important;
  }
  .p-2\:lg {
    padding: 10px !important;
  }
  .pt-2\:lg,
  .py-2\:lg {
    padding-top: 10px !important;
  }
  .pr-2\:lg,
  .px-2\:lg {
    padding-right: 10px !important;
  }
  .pb-2\:lg,
  .py-2\:lg {
    padding-bottom: 10px !important;
  }
  .pl-2\:lg,
  .px-2\:lg {
    padding-left: 10px !important;
  }
  .p-3\:lg {
    padding: 15px !important;
  }
  .pt-3\:lg,
  .py-3\:lg {
    padding-top: 15px !important;
  }
  .pr-3\:lg,
  .px-3\:lg {
    padding-right: 15px !important;
  }
  .pb-3\:lg,
  .py-3\:lg {
    padding-bottom: 15px !important;
  }
  .pl-3\:lg,
  .px-3\:lg {
    padding-left: 15px !important;
  }
  .p-4\:lg {
    padding: 20px !important;
  }
  .pt-4\:lg,
  .py-4\:lg {
    padding-top: 20px !important;
  }
  .pr-4\:lg,
  .px-4\:lg {
    padding-right: 20px !important;
  }
  .pb-4\:lg,
  .py-4\:lg {
    padding-bottom: 20px !important;
  }
  .pl-4\:lg,
  .px-4\:lg {
    padding-left: 20px !important;
  }
  .p-5\:lg {
    padding: 25px !important;
  }
  .pt-5\:lg,
  .py-5\:lg {
    padding-top: 25px !important;
  }
  .pr-5\:lg,
  .px-5\:lg {
    padding-right: 25px !important;
  }
  .pb-5\:lg,
  .py-5\:lg {
    padding-bottom: 25px !important;
  }
  .pl-5\:lg,
  .px-5\:lg {
    padding-left: 25px !important;
  }
  .p-6\:lg {
    padding: 30px !important;
  }
  .pt-6\:lg,
  .py-6\:lg {
    padding-top: 30px !important;
  }
  .pr-6\:lg,
  .px-6\:lg {
    padding-right: 30px !important;
  }
  .pb-6\:lg,
  .py-6\:lg {
    padding-bottom: 30px !important;
  }
  .pl-6\:lg,
  .px-6\:lg {
    padding-left: 30px !important;
  }
  .p-7\:lg {
    padding: 35px !important;
  }
  .pt-7\:lg,
  .py-7\:lg {
    padding-top: 35px !important;
  }
  .pr-7\:lg,
  .px-7\:lg {
    padding-right: 35px !important;
  }
  .pb-7\:lg,
  .py-7\:lg {
    padding-bottom: 35px !important;
  }
  .pl-7\:lg,
  .px-7\:lg {
    padding-left: 35px !important;
  }
  .p-8\:lg {
    padding: 40px !important;
  }
  .pt-8\:lg,
  .py-8\:lg {
    padding-top: 40px !important;
  }
  .pr-8\:lg,
  .px-8\:lg {
    padding-right: 40px !important;
  }
  .pb-8\:lg,
  .py-8\:lg {
    padding-bottom: 40px !important;
  }
  .pl-8\:lg,
  .px-8\:lg {
    padding-left: 40px !important;
  }
  .p-9\:lg {
    padding: 45px !important;
  }
  .pt-9\:lg,
  .py-9\:lg {
    padding-top: 45px !important;
  }
  .pr-9\:lg,
  .px-9\:lg {
    padding-right: 45px !important;
  }
  .pb-9\:lg,
  .py-9\:lg {
    padding-bottom: 45px !important;
  }
  .pl-9\:lg,
  .px-9\:lg {
    padding-left: 45px !important;
  }
  .p-10\:lg {
    padding: 50px !important;
  }
  .pt-10\:lg,
  .py-10\:lg {
    padding-top: 50px !important;
  }
  .pr-10\:lg,
  .px-10\:lg {
    padding-right: 50px !important;
  }
  .pb-10\:lg,
  .py-10\:lg {
    padding-bottom: 50px !important;
  }
  .pl-10\:lg,
  .px-10\:lg {
    padding-left: 50px !important;
  }
  .m-auto\:lg {
    margin: auto !important;
  }
  .mt-auto\:lg,
  .my-auto\:lg {
    margin-top: auto !important;
  }
  .mr-auto\:lg,
  .mx-auto\:lg {
    margin-right: auto !important;
  }
  .mb-auto\:lg,
  .my-auto\:lg {
    margin-bottom: auto !important;
  }
  .ml-auto\:lg,
  .mx-auto\:lg {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-0\:xl {
    margin: 0 !important;
  }
  .mt-0\:xl,
  .my-0\:xl {
    margin-top: 0 !important;
  }
  .mr-0\:xl,
  .mx-0\:xl {
    margin-right: 0 !important;
  }
  .mb-0\:xl,
  .my-0\:xl {
    margin-bottom: 0 !important;
  }
  .ml-0\:xl,
  .mx-0\:xl {
    margin-left: 0 !important;
  }
  .m-1\:xl {
    margin: 5px !important;
  }
  .mt-1\:xl,
  .my-1\:xl {
    margin-top: 5px !important;
  }
  .mr-1\:xl,
  .mx-1\:xl {
    margin-right: 5px !important;
  }
  .mb-1\:xl,
  .my-1\:xl {
    margin-bottom: 5px !important;
  }
  .ml-1\:xl,
  .mx-1\:xl {
    margin-left: 5px !important;
  }
  .m-2\:xl {
    margin: 10px !important;
  }
  .mt-2\:xl,
  .my-2\:xl {
    margin-top: 10px !important;
  }
  .mr-2\:xl,
  .mx-2\:xl {
    margin-right: 10px !important;
  }
  .mb-2\:xl,
  .my-2\:xl {
    margin-bottom: 10px !important;
  }
  .ml-2\:xl,
  .mx-2\:xl {
    margin-left: 10px !important;
  }
  .m-3\:xl {
    margin: 15px !important;
  }
  .mt-3\:xl,
  .my-3\:xl {
    margin-top: 15px !important;
  }
  .mr-3\:xl,
  .mx-3\:xl {
    margin-right: 15px !important;
  }
  .mb-3\:xl,
  .my-3\:xl {
    margin-bottom: 15px !important;
  }
  .ml-3\:xl,
  .mx-3\:xl {
    margin-left: 15px !important;
  }
  .m-4\:xl {
    margin: 20px !important;
  }
  .mt-4\:xl,
  .my-4\:xl {
    margin-top: 20px !important;
  }
  .mr-4\:xl,
  .mx-4\:xl {
    margin-right: 20px !important;
  }
  .mb-4\:xl,
  .my-4\:xl {
    margin-bottom: 20px !important;
  }
  .ml-4\:xl,
  .mx-4\:xl {
    margin-left: 20px !important;
  }
  .m-5\:xl {
    margin: 25px !important;
  }
  .mt-5\:xl,
  .my-5\:xl {
    margin-top: 25px !important;
  }
  .mr-5\:xl,
  .mx-5\:xl {
    margin-right: 25px !important;
  }
  .mb-5\:xl,
  .my-5\:xl {
    margin-bottom: 25px !important;
  }
  .ml-5\:xl,
  .mx-5\:xl {
    margin-left: 25px !important;
  }
  .m-6\:xl {
    margin: 30px !important;
  }
  .mt-6\:xl,
  .my-6\:xl {
    margin-top: 30px !important;
  }
  .mr-6\:xl,
  .mx-6\:xl {
    margin-right: 30px !important;
  }
  .mb-6\:xl,
  .my-6\:xl {
    margin-bottom: 30px !important;
  }
  .ml-6\:xl,
  .mx-6\:xl {
    margin-left: 30px !important;
  }
  .m-7\:xl {
    margin: 35px !important;
  }
  .mt-7\:xl,
  .my-7\:xl {
    margin-top: 35px !important;
  }
  .mr-7\:xl,
  .mx-7\:xl {
    margin-right: 35px !important;
  }
  .mb-7\:xl,
  .my-7\:xl {
    margin-bottom: 35px !important;
  }
  .ml-7\:xl,
  .mx-7\:xl {
    margin-left: 35px !important;
  }
  .m-8\:xl {
    margin: 40px !important;
  }
  .mt-8\:xl,
  .my-8\:xl {
    margin-top: 40px !important;
  }
  .mr-8\:xl,
  .mx-8\:xl {
    margin-right: 40px !important;
  }
  .mb-8\:xl,
  .my-8\:xl {
    margin-bottom: 40px !important;
  }
  .ml-8\:xl,
  .mx-8\:xl {
    margin-left: 40px !important;
  }
  .m-9\:xl {
    margin: 45px !important;
  }
  .mt-9\:xl,
  .my-9\:xl {
    margin-top: 45px !important;
  }
  .mr-9\:xl,
  .mx-9\:xl {
    margin-right: 45px !important;
  }
  .mb-9\:xl,
  .my-9\:xl {
    margin-bottom: 45px !important;
  }
  .ml-9\:xl,
  .mx-9\:xl {
    margin-left: 45px !important;
  }
  .m-10\:xl {
    margin: 50px !important;
  }
  .mt-10\:xl,
  .my-10\:xl {
    margin-top: 50px !important;
  }
  .mr-10\:xl,
  .mx-10\:xl {
    margin-right: 50px !important;
  }
  .mb-10\:xl,
  .my-10\:xl {
    margin-bottom: 50px !important;
  }
  .ml-10\:xl,
  .mx-10\:xl {
    margin-left: 50px !important;
  }
  .p-0\:xl {
    padding: 0 !important;
  }
  .pt-0\:xl,
  .py-0\:xl {
    padding-top: 0 !important;
  }
  .pr-0\:xl,
  .px-0\:xl {
    padding-right: 0 !important;
  }
  .pb-0\:xl,
  .py-0\:xl {
    padding-bottom: 0 !important;
  }
  .pl-0\:xl,
  .px-0\:xl {
    padding-left: 0 !important;
  }
  .p-1\:xl {
    padding: 5px !important;
  }
  .pt-1\:xl,
  .py-1\:xl {
    padding-top: 5px !important;
  }
  .pr-1\:xl,
  .px-1\:xl {
    padding-right: 5px !important;
  }
  .pb-1\:xl,
  .py-1\:xl {
    padding-bottom: 5px !important;
  }
  .pl-1\:xl,
  .px-1\:xl {
    padding-left: 5px !important;
  }
  .p-2\:xl {
    padding: 10px !important;
  }
  .pt-2\:xl,
  .py-2\:xl {
    padding-top: 10px !important;
  }
  .pr-2\:xl,
  .px-2\:xl {
    padding-right: 10px !important;
  }
  .pb-2\:xl,
  .py-2\:xl {
    padding-bottom: 10px !important;
  }
  .pl-2\:xl,
  .px-2\:xl {
    padding-left: 10px !important;
  }
  .p-3\:xl {
    padding: 15px !important;
  }
  .pt-3\:xl,
  .py-3\:xl {
    padding-top: 15px !important;
  }
  .pr-3\:xl,
  .px-3\:xl {
    padding-right: 15px !important;
  }
  .pb-3\:xl,
  .py-3\:xl {
    padding-bottom: 15px !important;
  }
  .pl-3\:xl,
  .px-3\:xl {
    padding-left: 15px !important;
  }
  .p-4\:xl {
    padding: 20px !important;
  }
  .pt-4\:xl,
  .py-4\:xl {
    padding-top: 20px !important;
  }
  .pr-4\:xl,
  .px-4\:xl {
    padding-right: 20px !important;
  }
  .pb-4\:xl,
  .py-4\:xl {
    padding-bottom: 20px !important;
  }
  .pl-4\:xl,
  .px-4\:xl {
    padding-left: 20px !important;
  }
  .p-5\:xl {
    padding: 25px !important;
  }
  .pt-5\:xl,
  .py-5\:xl {
    padding-top: 25px !important;
  }
  .pr-5\:xl,
  .px-5\:xl {
    padding-right: 25px !important;
  }
  .pb-5\:xl,
  .py-5\:xl {
    padding-bottom: 25px !important;
  }
  .pl-5\:xl,
  .px-5\:xl {
    padding-left: 25px !important;
  }
  .p-6\:xl {
    padding: 30px !important;
  }
  .pt-6\:xl,
  .py-6\:xl {
    padding-top: 30px !important;
  }
  .pr-6\:xl,
  .px-6\:xl {
    padding-right: 30px !important;
  }
  .pb-6\:xl,
  .py-6\:xl {
    padding-bottom: 30px !important;
  }
  .pl-6\:xl,
  .px-6\:xl {
    padding-left: 30px !important;
  }
  .p-7\:xl {
    padding: 35px !important;
  }
  .pt-7\:xl,
  .py-7\:xl {
    padding-top: 35px !important;
  }
  .pr-7\:xl,
  .px-7\:xl {
    padding-right: 35px !important;
  }
  .pb-7\:xl,
  .py-7\:xl {
    padding-bottom: 35px !important;
  }
  .pl-7\:xl,
  .px-7\:xl {
    padding-left: 35px !important;
  }
  .p-8\:xl {
    padding: 40px !important;
  }
  .pt-8\:xl,
  .py-8\:xl {
    padding-top: 40px !important;
  }
  .pr-8\:xl,
  .px-8\:xl {
    padding-right: 40px !important;
  }
  .pb-8\:xl,
  .py-8\:xl {
    padding-bottom: 40px !important;
  }
  .pl-8\:xl,
  .px-8\:xl {
    padding-left: 40px !important;
  }
  .p-9\:xl {
    padding: 45px !important;
  }
  .pt-9\:xl,
  .py-9\:xl {
    padding-top: 45px !important;
  }
  .pr-9\:xl,
  .px-9\:xl {
    padding-right: 45px !important;
  }
  .pb-9\:xl,
  .py-9\:xl {
    padding-bottom: 45px !important;
  }
  .pl-9\:xl,
  .px-9\:xl {
    padding-left: 45px !important;
  }
  .p-10\:xl {
    padding: 50px !important;
  }
  .pt-10\:xl,
  .py-10\:xl {
    padding-top: 50px !important;
  }
  .pr-10\:xl,
  .px-10\:xl {
    padding-right: 50px !important;
  }
  .pb-10\:xl,
  .py-10\:xl {
    padding-bottom: 50px !important;
  }
  .pl-10\:xl,
  .px-10\:xl {
    padding-left: 50px !important;
  }
  .m-auto\:xl {
    margin: auto !important;
  }
  .mt-auto\:xl,
  .my-auto\:xl {
    margin-top: auto !important;
  }
  .mr-auto\:xl,
  .mx-auto\:xl {
    margin-right: auto !important;
  }
  .mb-auto\:xl,
  .my-auto\:xl {
    margin-bottom: auto !important;
  }
  .ml-auto\:xl,
  .mx-auto\:xl {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-0\:xxl {
    margin: 0 !important;
  }
  .mt-0\:xxl,
  .my-0\:xxl {
    margin-top: 0 !important;
  }
  .mr-0\:xxl,
  .mx-0\:xxl {
    margin-right: 0 !important;
  }
  .mb-0\:xxl,
  .my-0\:xxl {
    margin-bottom: 0 !important;
  }
  .ml-0\:xxl,
  .mx-0\:xxl {
    margin-left: 0 !important;
  }
  .m-1\:xxl {
    margin: 5px !important;
  }
  .mt-1\:xxl,
  .my-1\:xxl {
    margin-top: 5px !important;
  }
  .mr-1\:xxl,
  .mx-1\:xxl {
    margin-right: 5px !important;
  }
  .mb-1\:xxl,
  .my-1\:xxl {
    margin-bottom: 5px !important;
  }
  .ml-1\:xxl,
  .mx-1\:xxl {
    margin-left: 5px !important;
  }
  .m-2\:xxl {
    margin: 10px !important;
  }
  .mt-2\:xxl,
  .my-2\:xxl {
    margin-top: 10px !important;
  }
  .mr-2\:xxl,
  .mx-2\:xxl {
    margin-right: 10px !important;
  }
  .mb-2\:xxl,
  .my-2\:xxl {
    margin-bottom: 10px !important;
  }
  .ml-2\:xxl,
  .mx-2\:xxl {
    margin-left: 10px !important;
  }
  .m-3\:xxl {
    margin: 15px !important;
  }
  .mt-3\:xxl,
  .my-3\:xxl {
    margin-top: 15px !important;
  }
  .mr-3\:xxl,
  .mx-3\:xxl {
    margin-right: 15px !important;
  }
  .mb-3\:xxl,
  .my-3\:xxl {
    margin-bottom: 15px !important;
  }
  .ml-3\:xxl,
  .mx-3\:xxl {
    margin-left: 15px !important;
  }
  .m-4\:xxl {
    margin: 20px !important;
  }
  .mt-4\:xxl,
  .my-4\:xxl {
    margin-top: 20px !important;
  }
  .mr-4\:xxl,
  .mx-4\:xxl {
    margin-right: 20px !important;
  }
  .mb-4\:xxl,
  .my-4\:xxl {
    margin-bottom: 20px !important;
  }
  .ml-4\:xxl,
  .mx-4\:xxl {
    margin-left: 20px !important;
  }
  .m-5\:xxl {
    margin: 25px !important;
  }
  .mt-5\:xxl,
  .my-5\:xxl {
    margin-top: 25px !important;
  }
  .mr-5\:xxl,
  .mx-5\:xxl {
    margin-right: 25px !important;
  }
  .mb-5\:xxl,
  .my-5\:xxl {
    margin-bottom: 25px !important;
  }
  .ml-5\:xxl,
  .mx-5\:xxl {
    margin-left: 25px !important;
  }
  .m-6\:xxl {
    margin: 30px !important;
  }
  .mt-6\:xxl,
  .my-6\:xxl {
    margin-top: 30px !important;
  }
  .mr-6\:xxl,
  .mx-6\:xxl {
    margin-right: 30px !important;
  }
  .mb-6\:xxl,
  .my-6\:xxl {
    margin-bottom: 30px !important;
  }
  .ml-6\:xxl,
  .mx-6\:xxl {
    margin-left: 30px !important;
  }
  .m-7\:xxl {
    margin: 35px !important;
  }
  .mt-7\:xxl,
  .my-7\:xxl {
    margin-top: 35px !important;
  }
  .mr-7\:xxl,
  .mx-7\:xxl {
    margin-right: 35px !important;
  }
  .mb-7\:xxl,
  .my-7\:xxl {
    margin-bottom: 35px !important;
  }
  .ml-7\:xxl,
  .mx-7\:xxl {
    margin-left: 35px !important;
  }
  .m-8\:xxl {
    margin: 40px !important;
  }
  .mt-8\:xxl,
  .my-8\:xxl {
    margin-top: 40px !important;
  }
  .mr-8\:xxl,
  .mx-8\:xxl {
    margin-right: 40px !important;
  }
  .mb-8\:xxl,
  .my-8\:xxl {
    margin-bottom: 40px !important;
  }
  .ml-8\:xxl,
  .mx-8\:xxl {
    margin-left: 40px !important;
  }
  .m-9\:xxl {
    margin: 45px !important;
  }
  .mt-9\:xxl,
  .my-9\:xxl {
    margin-top: 45px !important;
  }
  .mr-9\:xxl,
  .mx-9\:xxl {
    margin-right: 45px !important;
  }
  .mb-9\:xxl,
  .my-9\:xxl {
    margin-bottom: 45px !important;
  }
  .ml-9\:xxl,
  .mx-9\:xxl {
    margin-left: 45px !important;
  }
  .m-10\:xxl {
    margin: 50px !important;
  }
  .mt-10\:xxl,
  .my-10\:xxl {
    margin-top: 50px !important;
  }
  .mr-10\:xxl,
  .mx-10\:xxl {
    margin-right: 50px !important;
  }
  .mb-10\:xxl,
  .my-10\:xxl {
    margin-bottom: 50px !important;
  }
  .ml-10\:xxl,
  .mx-10\:xxl {
    margin-left: 50px !important;
  }
  .p-0\:xxl {
    padding: 0 !important;
  }
  .pt-0\:xxl,
  .py-0\:xxl {
    padding-top: 0 !important;
  }
  .pr-0\:xxl,
  .px-0\:xxl {
    padding-right: 0 !important;
  }
  .pb-0\:xxl,
  .py-0\:xxl {
    padding-bottom: 0 !important;
  }
  .pl-0\:xxl,
  .px-0\:xxl {
    padding-left: 0 !important;
  }
  .p-1\:xxl {
    padding: 5px !important;
  }
  .pt-1\:xxl,
  .py-1\:xxl {
    padding-top: 5px !important;
  }
  .pr-1\:xxl,
  .px-1\:xxl {
    padding-right: 5px !important;
  }
  .pb-1\:xxl,
  .py-1\:xxl {
    padding-bottom: 5px !important;
  }
  .pl-1\:xxl,
  .px-1\:xxl {
    padding-left: 5px !important;
  }
  .p-2\:xxl {
    padding: 10px !important;
  }
  .pt-2\:xxl,
  .py-2\:xxl {
    padding-top: 10px !important;
  }
  .pr-2\:xxl,
  .px-2\:xxl {
    padding-right: 10px !important;
  }
  .pb-2\:xxl,
  .py-2\:xxl {
    padding-bottom: 10px !important;
  }
  .pl-2\:xxl,
  .px-2\:xxl {
    padding-left: 10px !important;
  }
  .p-3\:xxl {
    padding: 15px !important;
  }
  .pt-3\:xxl,
  .py-3\:xxl {
    padding-top: 15px !important;
  }
  .pr-3\:xxl,
  .px-3\:xxl {
    padding-right: 15px !important;
  }
  .pb-3\:xxl,
  .py-3\:xxl {
    padding-bottom: 15px !important;
  }
  .pl-3\:xxl,
  .px-3\:xxl {
    padding-left: 15px !important;
  }
  .p-4\:xxl {
    padding: 20px !important;
  }
  .pt-4\:xxl,
  .py-4\:xxl {
    padding-top: 20px !important;
  }
  .pr-4\:xxl,
  .px-4\:xxl {
    padding-right: 20px !important;
  }
  .pb-4\:xxl,
  .py-4\:xxl {
    padding-bottom: 20px !important;
  }
  .pl-4\:xxl,
  .px-4\:xxl {
    padding-left: 20px !important;
  }
  .p-5\:xxl {
    padding: 25px !important;
  }
  .pt-5\:xxl,
  .py-5\:xxl {
    padding-top: 25px !important;
  }
  .pr-5\:xxl,
  .px-5\:xxl {
    padding-right: 25px !important;
  }
  .pb-5\:xxl,
  .py-5\:xxl {
    padding-bottom: 25px !important;
  }
  .pl-5\:xxl,
  .px-5\:xxl {
    padding-left: 25px !important;
  }
  .p-6\:xxl {
    padding: 30px !important;
  }
  .pt-6\:xxl,
  .py-6\:xxl {
    padding-top: 30px !important;
  }
  .pr-6\:xxl,
  .px-6\:xxl {
    padding-right: 30px !important;
  }
  .pb-6\:xxl,
  .py-6\:xxl {
    padding-bottom: 30px !important;
  }
  .pl-6\:xxl,
  .px-6\:xxl {
    padding-left: 30px !important;
  }
  .p-7\:xxl {
    padding: 35px !important;
  }
  .pt-7\:xxl,
  .py-7\:xxl {
    padding-top: 35px !important;
  }
  .pr-7\:xxl,
  .px-7\:xxl {
    padding-right: 35px !important;
  }
  .pb-7\:xxl,
  .py-7\:xxl {
    padding-bottom: 35px !important;
  }
  .pl-7\:xxl,
  .px-7\:xxl {
    padding-left: 35px !important;
  }
  .p-8\:xxl {
    padding: 40px !important;
  }
  .pt-8\:xxl,
  .py-8\:xxl {
    padding-top: 40px !important;
  }
  .pr-8\:xxl,
  .px-8\:xxl {
    padding-right: 40px !important;
  }
  .pb-8\:xxl,
  .py-8\:xxl {
    padding-bottom: 40px !important;
  }
  .pl-8\:xxl,
  .px-8\:xxl {
    padding-left: 40px !important;
  }
  .p-9\:xxl {
    padding: 45px !important;
  }
  .pt-9\:xxl,
  .py-9\:xxl {
    padding-top: 45px !important;
  }
  .pr-9\:xxl,
  .px-9\:xxl {
    padding-right: 45px !important;
  }
  .pb-9\:xxl,
  .py-9\:xxl {
    padding-bottom: 45px !important;
  }
  .pl-9\:xxl,
  .px-9\:xxl {
    padding-left: 45px !important;
  }
  .p-10\:xxl {
    padding: 50px !important;
  }
  .pt-10\:xxl,
  .py-10\:xxl {
    padding-top: 50px !important;
  }
  .pr-10\:xxl,
  .px-10\:xxl {
    padding-right: 50px !important;
  }
  .pb-10\:xxl,
  .py-10\:xxl {
    padding-bottom: 50px !important;
  }
  .pl-10\:xxl,
  .px-10\:xxl {
    padding-left: 50px !important;
  }
  .m-auto\:xxl {
    margin: auto !important;
  }
  .mt-auto\:xxl,
  .my-auto\:xxl {
    margin-top: auto !important;
  }
  .mr-auto\:xxl,
  .mx-auto\:xxl {
    margin-right: auto !important;
  }
  .mb-auto\:xxl,
  .my-auto\:xxl {
    margin-bottom: auto !important;
  }
  .ml-auto\:xxl,
  .mx-auto\:xxl {
    margin-left: auto !important;
  }
}
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-left\:sm {
    text-align: left !important;
  }
  .text-right\:sm {
    text-align: right !important;
  }
  .text-center\:sm {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-left\:md {
    text-align: left !important;
  }
  .text-right\:md {
    text-align: right !important;
  }
  .text-center\:md {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-left\:lg {
    text-align: left !important;
  }
  .text-right\:lg {
    text-align: right !important;
  }
  .text-center\:lg {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-left\:xl {
    text-align: left !important;
  }
  .text-right\:xl {
    text-align: right !important;
  }
  .text-center\:xl {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-left\:xxl {
    text-align: left !important;
  }
  .text-right\:xxl {
    text-align: right !important;
  }
  .text-center\:xxl {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-light {
  font-weight: 100 !important;
}

.font-normal {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-black {
  font-weight: 900 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-color {
  color: var(--color-black) !important;
}

.text-muted {
  color: var(--color-gray-500) !important;
}

.text-inherit {
  color: inherit !important;
}

.text-brand {
  color: #008fd5 !important;
}

.text-success {
  color: #02b85d !important;
}

.text-warning {
  color: #f0b800 !important;
}

.text-danger {
  color: #ea484a !important;
}

.copy > :first-child {
  margin-top: 0 !important;
}
.copy > :last-child {
  margin-bottom: 0 !important;
}
.copy h1, .copy h2, .copy h3, .copy h4, .copy h5, .copy h6,
.copy p, .copy ul, .copy ol {
  margin-top: 15px;
  margin-bottom: 15px;
}
.copy ul li:not(:last-child),
.copy ol li:not(:last-child) {
  margin-bottom: 15px;
}
.copy a {
  color: var(--color-brand);
  transition: color 250ms ease-in-out;
}
.copy a:hover {
  color: var(--color-brand-light);
  text-decoration: underline;
}
.copy iframe {
  display: block;
  width: 100%;
  height: auto;
}
.copy img {
  display: block;
  width: 100%;
  height: auto;
}

.o-hidden {
  overflow: hidden !important;
}

.o-visible {
  overflow: visible !important;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.nowrap {
  white-space: nowrap !important;
}

[hidden] {
  display: none !important;
}

.border-t {
  border-top: 1px solid #798fa3;
}