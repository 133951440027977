// Mixins
// ----------------------------------------

@mixin btn-reset {
	-webkit-tap-highlight-color: transparent;

	cursor: pointer;
	border: none;
	background-color: transparent;
	user-select: none;
	outline: none;
	white-space: nowrap;
	vertical-align: middle;
	margin: 0;
	padding: 0;
}

@mixin list-reset {
	list-style: none;
	padding-left: 0;
	margin-top: 0;
	margin-bottom: 0;
}

@mixin input-reset {
	outline: none;
	border: none;
	border-radius: 0;
	box-shadow: none;
}

@mixin input-select-reset {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

@mixin text-content() {
	> :first-child {
		margin-top: 0 !important;
	}

	> :last-child {
		margin-bottom: 0 !important;
	}
}

@mixin visually-hidden() {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

@mixin font-base() {
	font-family: $font-family-base;
	font-weight: $font-weight-base;
}

@mixin font-alt() {
	font-family: $font-family-alt;
	font-weight: $font-weight-alt;
}

@mixin smooth-gradient($orientation: 'to left', $color: $color-gray-100) {
	background-image: linear-gradient(
		var(--gradient-orientation, #{$orientation}),
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 1) 0%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.738) 19%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.541) 34%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.382) 47%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.278) 56.5%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.194) 65%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.126) 73%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.075) 80.2%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.042) 86.1%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.021) 91%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.008) 95.2%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0.002) 98.2%,
		rgba(var(--gradient-color, #{hex-to-rgb($color)}), 0) 100%
	);
}

@function hex-to-rgb($color) {
	@return red($color) + ', ' + green($color) + ', ' + blue($color);
}
