// Misc Utils
// ----------------------------------------

.o-hidden {
	overflow: hidden !important;
}

.o-visible {
	overflow: visible !important;
}

.list-unstyled {
	@include list-reset;
}

.nowrap {
	white-space: nowrap !important;
}

[hidden] {
	display: none !important;
}

.border-t {
	border-top: 1px solid $color-gray-700;
}
