:root {
	// ----------------------------------------
	// Colors
	// ----------------------------------------

	@each $name, $map in $theme-colors {
		--color-#{$name}: #{map-get($map, 'base')};
		--color-#{$name}-rgb: #{hex-to-rgb(map-get($map, 'base'))};
		--color-#{$name}-light: #{map-get($map, 'light')};
		--color-#{$name}-light-rgb: #{hex-to-rgb(map-get($map, 'light'))};
		--color-#{$name}-contrast: #{map-get($map, 'contrast')};
		--color-#{$name}-contrast-rgb: #{hex-to-rgb(map-get($map, 'contrast'))};
	}

	@each $name, $value in $neutral-colors {
		--color-#{$name}: #{$value};
		--color-#{$name}-rgb: #{hex-to-rgb($value)};
	}

	// ----------------------------------------
	// App
	// ----------------------------------------

	--ms-nav-size: 60px;
	--ms-spacing-x: 15px;
	--ms-spacing-y: 15px;

	@include media-breakpoint-up('sm') {
		--ms-spacing-y: 30px;
	}

	@include media-breakpoint-up('lg') {
		--ms-nav-size: 85px;
		--ms-spacing-x: 30px;
		--ms-spacing-y: 30px;
	}

	@include media-breakpoint-up('xl') {
		--ms-nav-size: 275px;
	}
}
